<template>
  <div>
    <!-- <view-hero>
      <template slot="title">Plan de Acción</template>
      <template slot="description">
        Desarrolla y gestiona el plan de acción que tu organización necesita
        para contribuir en el clima laboral.
      </template>
    </view-hero> -->
    <tab-nav :tabs="tabs" v-model="currentTab" />

    <div class="content">
      <component :is="currentTab.component" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Results from "./Results.vue";
import ViewActionPlans from "./ViewActionPlans.vue";
import ViewArchiveActionPlans from "./ViewArchiveActionPlans.vue";
import TabNav from "@/components/TabNav.vue";
import ViewHero from "../components/ViewHero.vue";

export default {
  name: "ActionPlan",

  components: {
    Results,
    ViewActionPlans,
    ViewArchiveActionPlans,
    TabNav,
    ViewHero
  },

  data: () => ({
    tabs: [
      {
        name: "Plan",
        component: "view-action-plans"
      },
      {
        name: "Archivo Histórico",
        component: "view-archive-action-plans"
      },
      {
        name: "Resultados",
        component: "results"
      }
    ],
    currentTab: {}
  }),

  computed: {
    ...mapState(["company", "surveys", "survey"])
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.content {
  flex-grow: 1;
}
</style>
