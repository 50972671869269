<template>
  <label class="file-uploader">
    <font-awesome-icon :icon="icons.fileUpload" />
    {{ label }}
    <input
      type="file"
      class="input"
      @input="$emit('input', $event.target.files[0])"
    />
  </label>
</template>

<script>
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "CustomFileInput",

  props: {
    value: File,

    label: String
  },

  data: () => ({
    icons: {
      fileUpload: faFileUpload
    }
  })
};
</script>

<style scoped>
.file-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--lengthSm1);
  padding: var(--lengthMd1);
  color: var(--fontColor1);
  font-weight: var(--semi-bold);
  border: 1px dashed var(--grayColor3);
  border-radius: var(--lengthSm1);
  cursor: pointer;
  transition: border-color 150ms;
}

.file-uploader:hover {
  color: var(--fontColor1);
  background-color: var(--grayColor2);
}

.input {
  display: none;
}
</style>
