var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Análisis de Resultados")]),_c('div',{ref:"chart",staticClass:"doughnut-chart__container",class:{ small: _vm.size < 140 },style:(_vm.sizeStyle)},[_c('doughnut-chart',{style:(_vm.sizeStyle),attrs:{"chartData":_vm.dataCollection,"options":{
        tooltips: { enabled: false },
        legend: {
          display: false
        },
        responsive: true,
        cutoutPercentage: 80
      }}}),_c('div',{staticClass:"ppc-percents"},[_c('div',{staticClass:"pcc-percents-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.value ? _vm.value : _vm.completeIndex[0])),_c('span',{staticClass:"percent-symbol"},[_vm._v("%")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],staticStyle:{"font-size":"1em"}},[_vm._v(_vm._s(_vm.label))])])])],1),_c('div',{staticClass:"content"},[_c('div',[_c('h2',[_vm._v("Realizado")]),_c('h1',{staticClass:"primary-color"},[_vm._v(_vm._s(_vm.completeIndex[0] + "")+"%")])]),_c('div',[_c('h2',[_vm._v("Pendiente")]),_c('h1',{staticClass:"secondary-color"},[_vm._v(_vm._s(_vm.completeIndex[1] + "")+"%")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }