<template>
  <div class="container">
    <div><h2>Inversión</h2></div>
    <div ref="chart">
      <div class="doughnut-chart__container" :style="sizeStyle">
        <doughnut-chart
          :chartData="dataCollection"
          :options="{
            tooltips: { enabled: false },
            legend: {
              display: false
            },
            responsive: true,
            cutoutPercentage: 80
          }"
        ></doughnut-chart>
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span
              >{{ expenseIndex[0] }}<span class="percent-symbol">%</span></span
            >
            <span v-show="label" style="font-size: 1em">{{ label }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div>
          <h1 class="primary-color">{{ expenseIndex[0] + "" }}%</h1>
          <h3>Gasto</h3>
        </div>
        <div>
          <h1 class="secondary-color">{{ expenseIndex[1] + "" }}%</h1>
          <h3 v-if="expenseIndex[1] >= 0">Saldo</h3>
          <h3 v-else>Exceso</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "../charts/DoughnutChart.vue";
export default {
  components: {
    DoughnutChart
  },
  data() {
    return {
      sizeStyle: "",
      size: null
    };
  },

  props: {
    actionPlans: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    label: {
      required: false
    },
    value: {
      required: false,
      default: false
    },
    expenseIndex: {
      type: Array,
      required: true
    }
  },

  methods: {},

  mounted() {
    this.size = this.$refs.chart.clientHeight;
    this.sizeStyle = { width: this.size - 20 + "px" };
  },

  computed: {
    dataCollection() {
      return {
        labels: ["Gasto", "Queda"],
        datasets: [
          {
            label: "dataset",
            data: this.expenseIndex[0] > 100 ? [100, 0] : this.expenseIndex,
            backgroundColor: this.colors,
            circumference: 80,
            hoverOffset: 4
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  gap: 1em;
  height: 100%;
  width: 100%;
  font-size: 16px;
}

h3 {
  color: var(--fontColor3);
  font-size: 1em;
}

.container > div {
  height: 1.7em;
}

.container > div:nth-child(2) {
  flex-grow: 1;
  display: flex;
  gap: 10%;
}

.container h2 {
  font-size: 1.2em;
}

h1 {
  font-size: 1.4em;
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.doughnut-chart__container {
  position: relative;
  display: flex;
  align-items: center;
}

.doughnut-chart__container > div {
  width: 85% !important;
  margin: 0 auto;
}

.primary-color {
  color: var(--primary-selected-color);
}

.secondary-color {
  color: var(--secondary-selected-color);
}

.nth-color {
  color: #f9293b;
}

.ppc-percents {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
  height: 82%;
  background: transparent;
  text-align: center;
  display: table;
}
.ppc-percents div > span {
  display: block;
  font-size: 2.6em;
  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  color: var(--primary-selected-color);
}
/* .small .ppc-percents span {
  font-size: 1.8em;
} */

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.ppc-percents .percent-symbol {
  font-size: 0.65em;
  width: fit-content;
}

@media (max-height: 1000px) {
  .container {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
