<template>
  <modal-2 ref="modal">
    <template slot="title"
      >¿Estás seguro que quieres eliminar el plan?</template
    >

    <custom-form @submit="onSubmit">
      <p>
        Una vez que se elimine no se podrá recuperar el plan de acción.
      </p>

      <template slot="buttons">
        <custom-button type="button" variant="text" @click="close">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="danger">
          Eliminar
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import CustomButton from "./CustomButton.vue";
import CustomForm from "./CustomForm.vue";
import Modal2 from "./Modal2.vue";

export default {
  name: "DeleteActionPlanModal",

  components: { Modal2, CustomForm, CustomButton },

  data: () => ({
    actionPlanId: "",
    fileName: ""
  }),

  methods: {
    ...mapActions(["deleteActionPlan"]),
    ...mapMutations(["setAlert"]),

    open(actionPlan) {
      this.actionPlanId = actionPlan.id;
      this.fileName = actionPlan.fileName;
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    async deletePlan(actionPlanID, fileName) {
      try {
        await this.deleteActionPlan({ actionPlanID, fileName });
        this.setAlert({
          state: "success",
          message: "Plan eliminado"
        });
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: error.message
        });
      }
    },

    async onSubmit() {
      await this.deletePlan(this.actionPlanId, this.fileName);
      this.close();
    }
  }
};
</script>

<style></style>
