<template>
  <modal-2 ref="modal">
    <template slot="title">Enviar Plan de Acción</template>

    <custom-form @submit="onSubmit">
      <p>
        Aquí podrá enviar el plan de acción al correo que desee. Recuerde que
        solo se permite hasta 5 envíos por correo.
      </p>

      <custom-input
        v-model="email"
        type="email"
        rules="required"
        label="Email"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="close">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary" :disabled="loading">
          {{ loading ? "Enviando..." : "Enviar" }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import CustomButton from "./CustomButton.vue";
import CustomForm from "./CustomForm.vue";
import CustomInput from "./CustomInput.vue";
import Modal2 from "./Modal2.vue";

export default {
  name: "SendActionPlanModal",

  components: { Modal2, CustomForm, CustomInput, CustomButton },

  data: () => ({
    email: "",
    actionPlan: {},
    loading: false
  }),

  methods: {
    ...mapActions(["sendActionPlanEmail"]),

    ...mapMutations(["setAlert"]),

    open(actionPlan) {
      this.actionPlan = JSON.parse(JSON.stringify(actionPlan));
      this.$refs.modal.open();
    },

    close() {
      this.actionPlan = {};
      this.$refs.modal.close();
    },

    getFormattedDate(rawDate) {
      if (rawDate.seconds) {
        return new Date(rawDate.seconds * 1000).toISOString().split("T")[0];
      } else return rawDate.toISOString().split("T")[0];
    },

    async onSubmit() {
      try {
        this.loading = true;

        const { data } = await this.sendActionPlanEmail({
          email: this.email,
          title: this.actionPlan.text,
          actionPlan: `
            Jerarquía: ${this.actionPlan.hierarchyName},
            Dimensión: ${this.actionPlan.dimensionName},
            Nombre: ${this.actionPlan.text},
            Impacto: ${this.actionPlan.impact},
            Responsable: ${this.actionPlan.inCharge},
            Fecha de Inicio: ${this.getFormattedDate(
              this.actionPlan.startDate
            )},
            Fecha de Fin: ${this.getFormattedDate(this.actionPlan.endDate)},
            Fecha de Cumplimiento: ${this.getFormattedDate(
              this.actionPlan.checkDate
            )},
            Moneda: ${this.actionPlan.currency},
            Presupuesto: ${this.actionPlan.budget},
            Gasto: ${this.actionPlan.expense}
          `
        });

        this.setAlert({
          state: "success",
          message: `Correo enviado, te quedan ${5 -
            data.sendCount} envíos a este correo`
        });

        this.close();
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: error.message
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
