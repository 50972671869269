<template>
  <label class="search-input">
    <span class="search-icon">
      <font-awesome-icon :icon="icons.search" />
    </span>

    <input
      type="search"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :placeholder="placeholder"
      class="input"
    />

    <button v-if="value" @click="$emit('input', '')" class="clear">
      <font-awesome-icon :icon="icons.close" />
    </button>
  </label>
</template>

<script>
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "SearchInput",

  props: {
    value: String,
    placeholder: {
      default: "Buscar",
      type: String
    }
  },

  data() {
    return {
      icons: {
        search: faSearch,
        close: faTimesCircle
      }
    };
  }
};
</script>

<style scoped>
.search-input {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: var(--lengthSm1);
  padding: var(--lengthSm2);
  border: 1px solid transparent;
  border-radius: var(--lengthSm1);
  cursor: text;
  transition: border-color 150ms;
}

.search-input:focus-within {
  border-color: var(--mainColor1);
}

.search-icon {
  color: var(--fontColor3);
}

.input {
  max-width: 200px;
  min-width: 0;
}

.input::placeholder {
  color: var(--fontColor3);
  font-weight: var(--medium);
}

.clear {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: var(--lengthSm2);
  color: var(--fontColor3);
  background-color: white;
  border-top-right-radius: var(--lengthSm2);
  border-bottom-right-radius: var(--lengthSm2);
  transition: color 150ms;
}

.clear:hover {
  color: var(--fontColor2);
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
