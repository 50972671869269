<template>
  <div class="container" @click="$emit('click')" v-click-outside="close">
    <icon-button
      :icon="icons.ellipsis"
      variant="secondary"
      label="More"
      @click="show = !show"
    >
    </icon-button>

    <div :class="show ? 'collapse' : 'collapse hide'">
      <div class="content">
        <div
          class="item mail"
          @click="
            $emit('sendMail');
            show = false;
          "
        >
          <font-awesome-icon :icon="icons.mail" slot="icon" />
          Enviar
        </div>
        <div
          class="item edit"
          @click="
            $emit('edit');
            show = false;
          "
        >
          <font-awesome-icon icon="pen" slot="icon" />
          Editar
        </div>
        <div
          v-if="archive"
          class="item archive"
          @click="
            $emit('archive');
            show = false;
          "
        >
          <font-awesome-icon :icon="icons.archive" slot="icon" />
          Archivar
        </div>
        <div
          class="item delete"
          @click="
            $emit('delete');
            show = false;
          "
        >
          <font-awesome-icon :icon="icons.trash" slot="icon" />
          Eliminar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {
  faEllipsisV,
  faEnvelope,
  faTrashAlt,
  faEdit,
  faArchive
} from "@fortawesome/free-solid-svg-icons";

import IconButton from "./IconButton.vue";

export default {
  name: "Ellipsis",

  components: { IconButton },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    show: false,

    icons: {
      ellipsis: faEllipsisV,
      mail: faEnvelope,
      trash: faTrashAlt,
      edit: faEdit,
      archive: faArchive
    }
  }),

  props: {
    archive: {
      default: true
    }
  },

  methods: {
    close() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  height: fit-content;
  box-shadow: 0 0 1px 1px var(--grayColor2) inset;
  border-radius: var(--lengthSm1);
}

.container button {
  width: var(--lengthLg1);
  height: var(--lengthLg1);
}

.collapse {
  position: absolute;
  top: 110%;
  left: 0;
  border-radius: 5px;
  background-color: white;
  width: fit-content;
  z-index: 1;
  border: solid 1px rgba(128, 128, 128, 0.26);
  width: 150px;
}

.collapse span {
  width: 100%;
}

.hide {
  display: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.item {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--lengthSm3);
  padding: var(--lengthSm3);
  color: var(--fontColor2);
  font-weight: var(--medium);
  transition: background-color 150ms;
}

.item:hover {
  color: var(--fontColor1);
  background-color: var(--grayColor1);
}

.content > div svg {
  fill: var(--fontColor1);
}

.edit svg,
.delete svg,
.mail svg,
.archive svg {
  height: 1em;
  width: 1em;
}

.edit,
.delete,
.archive {
  border-top: 1px solid var(--grayColor2);
}

.delete {
  color: var(--dangerColor);
}

.delete:hover {
  color: var(--dangerColor);
}
</style>
