<template>
  <modal-2 ref="modal">
    <template slot="title"
      >¿Estás seguro que quieres archivar el plan?</template
    >

    <custom-form @submit="onSubmit">
      <p>
        Una vez que se archive no se podrá recuperar el plan de acción. ni se
        revisaran los resultados en el dashboard del plan de acción
      </p>

      <template slot="buttons">
        <custom-button type="button" variant="text" @click="close">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary">
          Archivar
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import CustomButton from "./CustomButton.vue";
import CustomForm from "./CustomForm.vue";
import Modal2 from "./Modal2.vue";

export default {
  name: "DeleteActionPlanModal",

  components: { Modal2, CustomForm, CustomButton },

  data: () => ({
    actionPlan: {}
  }),

  methods: {
    ...mapActions(["editActionPlan"]),
    ...mapMutations(["setAlert"]),

    open(actionPlan) {
      this.actionPlan = { ...actionPlan };
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    async archivePlan(actionPlan) {
      try {
        await this.editActionPlan({
          ...actionPlan,
          archived: true
        });
        this.setAlert({
          state: "success",
          message: "Plan archivado"
        });
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: error.message
        });
      }
    },

    async onSubmit() {
      await this.archivePlan(this.actionPlan);
      this.close();
    }
  }
};
</script>

<style></style>
