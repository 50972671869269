export const currencies = [
  {
    code: "EUR",
    description: "Euro",
    symbol: "\u20ac"
  },
  {
    code: "USD",
    description: "US Dollar",
    symbol: "$"
  },
  {
    code: "PEN",
    description: "Sol",
    symbol: "S/"
  }
];
