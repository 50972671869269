<template>
  <div class="filter-box-container">
    <div class="content">
      <custom-select
        :label="'Filtro Variable'"
        :options="
          categories.map(category => {
            return { value: category.name, id: category.name };
          })
        "
        v-model="selectedHierarchy"
      ></custom-select>

      <div class="filters-container">
        <h4>Filtros Especificos</h4>
        <accordion
          :selectedValues="selectedFilters"
          v-on:addValue="addFilter"
        />
      </div>

      <div class="selected-values-container">
        <h4>Filtros Seleccionados</h4>

        <div
          class="selected-values placeholder"
          v-if="!Object.keys(selectedFilters).length"
        >
          <p>Añade un filtro +</p>
        </div>

        <div v-else class="selected-values">
          <template v-for="selectedFilter in Object.keys(selectedFilters)">
            <div
              class="value-tag"
              v-for="(selectedValue, index) in selectedFilters[selectedFilter]"
              :key="index + selectedValue"
            >
              <h5>{{ selectedValue }}</h5>
              <svg
                @click="removeFilter(selectedFilter, selectedValue)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <custom-button
        class="button"
        type="button"
        :variant="'text'"
        @click="$emit('closeModal')"
      >
        Cancelar
      </custom-button>

      <custom-button class="button" v-on:click="emitFilters">
        Filtrar
      </custom-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Accordion from "./Accordion.vue";
import CustomSelect from "../CustomSelect.vue";
import CustomButton from "../CustomButton.vue";
export default {
  name: "FilterBox",
  components: {
    Accordion,
    CustomSelect,
    CustomButton
  },
  data() {
    return {
      selectedHierarchy: "",
      selectedFilters: {}
    };
  },
  props: {
    startHierarchy: {
      type: String,
      required: true
    },
    startFilters: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions("filters", ["fetchFilters"]),
    addFilter({ option, value }) {
      let newSelectedFilters = { ...this.selectedFilters };
      if (option in this.selectedFilters)
        newSelectedFilters[option].push(value);
      else newSelectedFilters[option] = [value];

      this.selectedFilters = { ...newSelectedFilters };
    },

    removeFilter(selectedFilter, selectedValue) {
      let newSelectedFilters = { ...this.selectedFilters };
      let newSelectedvalues = this.selectedFilters[selectedFilter].filter(
        value => value != selectedValue
      );
      if (!newSelectedvalues.length) delete newSelectedFilters[selectedFilter];
      else newSelectedFilters[selectedFilter] = newSelectedvalues;
      this.selectedFilters = { ...newSelectedFilters };
    },

    emitFilters() {
      if (this.selectedHierarchy) {
        this.$emit("editFilters", {
          selectedFilters: { ...this.selectedFilters },
          selectedHierarchy: this.selectedHierarchy
        });
      }
    }
  },
  computed: {
    ...mapState({
      categories: state =>
        state.filters.filters.filter(filter => filter.orgHierarchy)
    })
  },
  async mounted() {
    await this.fetchFilters();
    this.selectedHierarchy = this.startHierarchy;
    this.selectedFilters = JSON.parse(JSON.stringify(this.startFilters));
  }
};
</script>

<style scoped>
* {
  text-transform: capitalize;
  user-select: none;
  text-align: left;
}

.filter-box-container .content {
  padding: 1em 1.5em 0 0;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
}

.filter-box-container .content > *:not(:last-child) {
  margin-bottom: 1em;
}

h5 {
  font-size: 12px;
  font-weight: var(--weight-semi-bold);
  margin: 0;
}

.filter-box-container .selected-values-container {
  width: 100%;
  margin-bottom: 0;
}

.selected-values {
  margin: var(--lengthSm2) 0;
  padding: var(--lengthSm1);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
}

.placeholder p {
  margin: 0.63em;
}

.value-tag {
  padding: 0.4rem var(--lengthSm3);
  border-radius: var(--lengthMd3);
  margin: var(--lengthSm1);
  background-color: #f7f7fc;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

svg {
  cursor: pointer;
  margin-left: 0.4rem;
  height: var(--lengthSm3);
  color: var(--color-text);
}

.button {
  width: 13em;
}

.flex {
  margin-top: var(--lengthMd3);
}

.select {
  flex-grow: unset;
}
</style>
