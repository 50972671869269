<template>
  <div class="accordion-container" v-click-outside="close">
    <div v-for="(category, index) in categories" :key="index" class="accordion">
      <div
        :class="active == category ? 'option active' : 'option'"
        @click="handleOpticlick(category)"
      >
        <p>{{ category.name }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="small-svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        :class="active == category ? 'panel active' : 'panel'"
        v-if="category.options && category.hasOwnProperty('options')"
      >
        <div
          v-for="(option, index) in filterSelects(category)"
          :key="index"
          @click="addValue(category.name, option.name)"
        >
          <p>
            {{ option.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { mapState } from "vuex";
export default {
  name: "Accordion",
  props: {
    selectedValues: {
      type: Object,
      required: true
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      active: ""
    };
  },
  methods: {
    close() {
      this.active = "";
    },
    handleOpticlick(option) {
      let active = this.active == option;
      this.active = active ? "" : option;
    },
    addValue(option, value) {
      this.active = "";
      this.$emit("addValue", { option: option, value: value });
    },
    filterSelects(category) {
      let selectedValues = this.selectedValues;
      let filteredOptions = category.options.filter(option =>
        category.name in selectedValues
          ? !selectedValues[category.name].includes(option.name)
          : true
      );

      if (
        category.hasParent &&
        this.getCategoryParents(category).some(value =>
          this.selectedHierarchyCategories.includes(value)
        )
      ) {
        filteredOptions = filteredOptions.filter(option => {
          return option.parentOption
            ? this.getParents(option.parentOption).some(value =>
                this.selectedHierarchyValues.includes(value)
              )
            : true;
        });
      }
      return filteredOptions;
    },

    getOption(optionName) {
      let result = {};
      let index = 0;
      let options = this.categories[index].options.filter(
        o => o.name === optionName
      );
      let categoryName = this.categories[index].name;
      while (!options.length) {
        index++;
        options = this.categories[index].options.filter(
          o => o.name === optionName
        );
        categoryName = this.categories[index].name;
      }
      result = { category: categoryName, option: options[0] };

      return result;
    },
    getParents(optionName) {
      let option = this.getOption(optionName);
      if (option) {
        let optionNames = [option.option.name];
        let parentName = option.option.parentOption;
        if (this.selectedHierarchyCategories.includes(option.category))
          parentName = "";
        while (parentName) {
          option = this.getOption(parentName);
          optionNames.push(option.option.name);
          if (this.selectedHierarchyCategories.includes(option.category))
            parentName = "";
          else {
            parentName = option.option.parentOption;
          }
        }
        return optionNames;
      }
      return [];
    },

    getCategory(id) {
      return this.categories.filter(category => category.id === id)[0];
    },
    getCategoryParents(cat) {
      let category = cat;
      let categoryNames = [];
      let parentId = category.parentId;
      while (parentId) {
        category = this.getCategory(parentId);
        categoryNames.push(category.name);
        parentId = category.parentId;
      }
      return categoryNames;
    }
  },

  computed: {
    ...mapState({
      categories: state =>
        state.filters.filters.filter(filter => filter.orgHierarchy)
    }),

    selectedHierarchyValues() {
      let selected = [];
      Object.keys(this.selectedValues).forEach(key => {
        if (!this.selectedValues[key].includes("Dimension", "Pregunta"))
          selected.push(...this.selectedValues[key]);
      });
      return selected;
    },

    selectedHierarchyCategories() {
      let selected = [];
      Object.keys(this.selectedValues).forEach(key => {
        if (!this.selectedValues[key].includes("Dimension", "Pregunta"))
          selected.push(key);
      });
      return selected;
    }
  }
};
</script>

<style scoped>
.accordion-container {
  border-radius: var(--lengthSm1);
}

.accordion:first-child {
  margin-top: var(--lengthSm2);
}

.accordion {
  margin-bottom: var(--lengthSm2);
  position: relative;
}
.option {
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
  text-transform: capitalize;
  cursor: pointer;
  padding: var(--lengthSm2) var(--lengthSm3);
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  user-select: none;
  transition: border-color 300ms;
}

.option.active {
  border-color: var(--mainColor1);
}

p {
  color: var(--fontColor1);
}

.panel {
  z-index: 9;
  width: 100%;
  background: white;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  max-height: 0;
  font-weight: var(--weight-regular);
}
.panel.active {
  box-shadow: -1px 0px 21px -6px rgba(0, 0, 0, 0.4);
  border: 1px solid black;
  overflow-y: auto;
  max-height: fit-content;
}

.panel div {
  padding: 0 var(--lengthSm3);
}

.panel div:hover {
  background-color: #1e90ff;
}

.panel div:hover p {
  color: white;
}

svg {
  height: 1.25em;
  color: var(--fontColor1);
}
</style>
