<template>
  <button
    @click="$emit('click', $event)"
    :type="type"
    class="icon-btn"
    :title="label"
    :class="btnVariant"
    :disabled="disabled"
  >
    <font-awesome-icon :icon="icon" size="lg" fixed-width />
  </button>
</template>

<script>
export default {
  name: "IconButton",

  props: {
    icon: [String, Object],
    label: String,
    variant: String,
    disabled: Boolean,
    type: {
      default: "button",
      type: String
    }
  },

  computed: {
    btnVariant() {
      switch (this.variant) {
        case "text":
          return "icon-btn--text";
        case "secondary":
          return "icon-btn--secondary";
        case "ghost":
          return "icon-btn--ghost";
        case "danger":
          return "icon-btn--danger";
        default:
          return "icon-btn--primary";
      }
    }
  }
};
</script>

<style scoped>
.icon-btn {
  position: relative;
  width: var(--lengthLg1);
  height: var(--lengthLg1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  border-radius: var(--lengthSm1);
  transition: color 150ms, background-color 150ms;
}

.icon-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.icon-btn:active:not(:disabled) {
  transform: translateY(1px);
}

.icon-btn--primary {
  color: white;
  background-color: var(--mainColor1);
  transition: background-color 300ms;
}

.icon-btn--primary:hover:not(:disabled) {
  background-color: var(--mainColor2);
}

.icon-btn--secondary {
  color: var(--fontColor1);
  background-color: white;
  box-shadow: 0 0 1px 1px var(--grayColor2) inset;
  transition: background-color 300ms;
}

.icon-btn--secondary:hover:not(:disabled) {
  background-color: var(--grayColor1);
}

.icon-btn--ghost {
  color: var(--mainColor1);
  box-shadow: 0 0 0 1px currentColor inset;
}

.icon-btn--danger {
  color: var(--dangerColor);
  box-shadow: 0 0 0 1px currentColor inset;
}

.icon-btn--text {
  color: var(--fontColor2);
  transition: color 300ms;
}

.icon-btn--text:hover:not(:disabled) {
  color: var(--fontColor1);
}
</style>
