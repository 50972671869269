<template>
  <div class="container">
    <div class="content">
      <div>
        <h2>Cantidad</h2>
      </div>
      <div class="number-container">
        <h1 class="primary-color">{{ actionPlans.length }}</h1>
        <h3>Planes de acción</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  props: {
    actionPlans: {
      type: Array,
      required: true
    }
  },

  methods: {},

  mounted() {},

  computed: {}
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 16px;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.2em;
}

.content {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.number-container h1 {
  font-size: 5em;
}

.content > div > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.content > div:first-child {
  display: flex;
  align-items: center;
  gap: 50%;
}

.content > div:nth-child(2) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.primary-color {
  color: var(--primary-selected-color);
}

@media (max-height: 1000px) {
  .container {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
