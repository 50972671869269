<template>
  <nav class="tab-nav">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      @click="setTab(tab)"
      class="tab"
      :class="{ 'tab--active': tab.name === selectedTab.name }"
    >
      {{ tab.name }}
    </button>
  </nav>
</template>

<script>
export default {
  name: "TabNav",

  model: {
    prop: "selectedTab",
    event: "change"
  },

  props: {
    tabs: Array,
    selectedTab: Object
  },

  methods: {
    setTab(tab) {
      this.$emit("change", tab);
    }
  },

  mounted() {
    this.setTab(this.tabs[0]);
  }
};
</script>

<style scoped>
.tab-nav {
  display: flex;
  align-items: center;
  gap: var(--lengthMd3);
  border-bottom: 1px solid var(--grayColor2);
}

.tab {
  padding-bottom: var(--lengthSm3);
  color: var(--fontColor3);
  font-weight: 500;
  border-radius: var(--lengthSm2);
  transition: color 150ms;
}

.tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-top-left-radius: var(--lengthSm1);
  border-top-right-radius: var(--lengthSm1);
  background-color: transparent;
  transition: background-color 150ms;
}

.tab--active {
  color: var(--mainColor1);
}

.tab--active::after {
  background-color: currentColor;
}
</style>
